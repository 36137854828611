.categorias {
    display: flex;
    justify-content: space-between;
    a {
        font-size: 1.25rem;
        &:hover {
            color: $primary-color-2;
        }
        @include media-breakpoint-down(xl) {
            font-size: 1.1rem;
            line-height: 1.1rem;
        }
        @include media-breakpoint-down(lg) {
            font-size: .9rem;
            line-height: .9rem;
        }
        @include media-breakpoint-down(md) {
            font-size: .65rem;
            line-height: .65rem;
        }
    }
}
