// Body
$body-bg: white;
// Typography
$font-family-primary: 'Arsenal',
sans-serif;
$font-family-secundary:'Open Sans',
sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
// zindex bootstrap
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1010 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
  7: 2rem,
  8: 1.5rem,
  9: 1rem,
  10: .875rem
);
$display-font-weight: 400;

// primary colors
$btn-color-1: #262C2C;
// primary colors
$primary-color-1: #4c5154;
$primary-color-2: #696F73;
$primary-color-3: #696F73;
$primary-color-4: #EBDAD3;
$primary-color-5: #E7DCBB;
// secondary colors
$secondary-color-1: #4c5154;
$secondary-color-2: #818C93;
$secondary-color-3: #97A7AF;
$secondary-color-4: #C4C4C4;
$secondary-color-5: #DFDFE4;
// alert colors
$alert-color-success-1: #2d8019;
$alert-color-success-2: #1BC042;
$alert-color-success-3: #00ff3c;
$alert-color-danger: #E03D3D;
$alert-color-warning: #FFD836;
//
$bg-menu: #F1F1F1;
$border-radius: .7em;
$heigth-banner: 480px;
// $accordion-button-focus-box-shadow: none;
// $accordion-button-active-color: $primary-color-1;
// $accordion-button-focus-border-color: none;
// $accordion-button-active-bg: $secondary-color-3;
$carousel-control-color: black;
// carrousel
$carousel-control-prev-icon-bg: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16"><path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/></svg>';


.my-carousel-indicators{
    bottom: -30px !important;
}
