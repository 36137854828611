.my-progress {
    z-index: $zindex-sticky;
    font-family: $font-family-secundary;
    position: relative;
    width: 100%;
    height: 20px;
    background-color: $primary-color-4;
    color: $primary-color-1;
    font-weight: 300;
    max-width: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    .my-bar {
        position: absolute;
        width: 0%;
        height: 100%;
        background: linear-gradient(90deg, #91f25f 1%, #00c6ab);
    }
    .bar-description {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 50;
        font-size: .75rem;
    }
}
