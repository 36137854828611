$size-of-hf: 253px;
.cart {
    width: 420px;
    max-width: 100%;
    max-height: 100dvh;
    height: 100%;
    background-color: #454545;
    box-shadow: -10px 0px 10px 1px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 20px;
    right: -520px;
    transition: all .5s ease;
    z-index: 99999;
    color: #E9E9E9;
    a {
        color: #bee2ff;
    }
    &--active {
        right: 0;
    }
    &__msg {
        font-size: 1.2rem;
        margin: 0 auto;
        padding: 30px 0;
        text-align: center;
    }
    &__items {
        overflow-y: auto;
        height: calc(100vh - #{$size-of-hf});
        &__item {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #6d6e70;
            position: relative;
            .holder {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: #fff;
                text-transform: uppercase;
                padding: 20px 35px 0 20px;
                min-height: 100%;
            }
            .product-remove {
                background: transparent;
                border: 0;
                position: absolute;
                top: 20px;
                right: 15px;
                color: #fff;
                cursor: pointer;
            }
            .product-image {
                max-width: 115px;
                width: 100%;
                background: #fff;
                img {
                    max-width: 100%;
                }
            }
            .product-info {
                margin-left: 10px;
            }
            .product-name {
                line-height: 1.2;
                font-size: 1rem;
                margin-bottom: 0;
            }
            .product-qty-price {
                color: #fff;
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.95rem;
                clear: both;
                .product__price__subtotal {
                    font-size: 1rem;
                }
                span {
                    display: inline-block;
                }
                .product-price {
                    position: relative;
                    top: 25px;
                }
            }
        }
        // &__item {
        //     position: relative;
        //     height: 170px;
        //     background-image: linear-gradient(to bottom right, #777777, #2E2E2E);
        // }
        .img-item {
            width: 90%;
            margin-top: 10px;
            margin-left: 5px;
        }
        .title {
            float: left;
            width: 70%;
            font-size: 14px !important;
            margin-top: 44px;
            line-height: 22px !important;
            text-transform: capitalize;
        }
        .price {
            position: absolute;
            bottom: 7px;
            font-size: 12px;
            span {
                font-weight: 800;
            }
        }
        .subtotal {
            position: absolute;
            bottom: 6px;
            font-size: 12px;
            float: left;
            span {
                font-weight: 800;
                font-size: 19px;
                margin-left: 3px;
            }
        }
        .prices {
            clear: both;
            float: left;
            width: 25%;
            padding: 10px 10px;
            .from {
                text-decoration: line-through;
                text-decoration-color: red;
            }
            .to {
                font-size: 14px;
            }
        }
        .qty {
            width: 22%;
            float: left;
            padding: 10px 10px;
        }
        .total {
            width: 28%;
            float: left;
            padding: 10px 10px;
            font-size: 18px;
        }
        .item {
            margin-top: 13px;
        }
    }
    #cart-header {
        font-size: 1.2rem;
        text-align: left;
        background-color: #2c2c2c;
        color: #E9E9E9;
        top: 4px;
        button:focus {
            box-shadow: none;
        }
    }
    .cart-footer-subtotal {
        margin-left: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .cart-footer-subtotal-value {
        margin-right: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: right;
        font-weight: 800;
    }
    .continue-buy {
        text-align: center;
        width: 100%;
        color: blue;
        margin-bottom: 15px;
    }
    #total {
        position: absolute;
        bottom: 0px;
        width: 100%;
        background-color: darken(#454545, 10%);
        #button-checkin {
            width: 100%;
            margin: 0;
            button.go-to-checkout {
                width: 100%;
                background-color: $alert-color-success-1;
                text-align: center;
                height: 64px;
                font-size: 14px;
                color: #fff;
                margin: 0 auto;
                letter-spacing: 2px;
            }
        }
    }
    input,
    textarea {
        border: 1px solid #fff;
        box-sizing: border-box;
        margin: 0;
        outline: none;
        padding: 10px;
    }
    input[type="button"] {
        -webkit-appearance: button;
        cursor: pointer;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .input-group {
        clear: both;
        margin: 15px 0;
        position: relative;
        width: 138px;
        float: left;
    }
    .input-group input[type='button'] {
        background-color: #eeeeee;
        min-width: 38px;
        width: auto;
        transition: all 300ms ease;
    }
    .input-group .button-minus,
    .input-group .button-plus {
        font-weight: bold;
        height: 38px;
        padding: 0;
        width: 38px;
        position: relative;
    }
    .input-group .quantity-field {
        position: relative;
        height: 38px;
        left: -6px;
        text-align: center;
        width: 62px;
        display: inline-block;
        font-size: 13px;
        margin: 0 0 5px;
        resize: vertical;
        background-color: #fff;
    }
    .button-plus {
        left: -13px;
    }
    input[type="number"] {
        -moz-appearance: textfield;
        -webkit-appearance: none;
    }
}
