#loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    background-color: $primary-color-1;
    display: none;
    &.is-visible {
        display: block;
    }
    .loading {
        $colors: $primary-color-2, $primary-color-3, $primary-color-4, $primary-color-5, white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .dot {
            position: relative;
            width: 2em;
            height: 2em;
            margin: 0.8em;
            border-radius: 50%;
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: inherit;
                border-radius: inherit;
                animation: wave 2s ease-out infinite;
            }
            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    background: nth($colors, $i);
                    &::before {
                        animation-delay: $i * 0.2s;
                    }
                }
            }
        }
    }
    @keyframes wave {
        50%,
        75% {
            transform: scale(2.5);
        }
        80%,
        100% {
            opacity: 0;
        }
    }
}