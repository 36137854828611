#dropdown {
    // text-transform: capitalize;
    .dropdown {
        display: flex;
        justify-content: space-between;
        p.title {
            font-family: $font-family-secundary;
            font-weight: 400;
            font-size: .9rem;
            line-height: .875rem;
            letter-spacing: 0.12em;

            color: $primary-color-1;
            text-wrap: nowrap;
        }

        .display-1 {
            margin-top: 3rem;
            font-family: $font-family-primary;
            font-size: 40px;
            line-height: 42px;
            letter-spacing: 0.02em;
            color: $primary-color-3;

        }
        .display-2 {
            margin-top: 2.25rem;
            margin-bottom: 1.5rem;
            font-family: $font-family-secundary;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.7rem;
            color: $secondary-color-2;

        }
        .drop-profissional,
        .drop-home,.drop-black {
            display: none;
            position: fixed;
            top: 65px;
            width: 100vw;
            left: 0;
            background-color: white;
            box-shadow: 0px 38px 38px rgba(10, 10, 10, 0.1);
            padding-bottom: 3rem;
            a {
                font-family: $font-family-secundary;
                color: $primary-color-1;
                font-size: .9rem !important;
                line-height: 1.3rem;
                letter-spacing: 1px;

                display: block;
                text-align: left;
                transition: .2s all;
                float: left;
                width: 45%;
                margin-bottom: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &:nth-child(2n+1) {
                    margin-left: 5%;
                }
                &:hover {
                    color: lighten(#646464, 15%);
                    text-decoration: underline;
                }
            }
        }
        .dropdown--profissional:hover .drop-profissional {
            display: block;
        }
        .dropdown--home:hover .drop-home {
            display: block;
        }
        .dropdown--black:hover .drop-black {
            display: block;
        }
    }
}
