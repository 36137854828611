#produto-mobile {
    .beneficios-mobile {
        ul {
            padding-inline-start: 20px;
            li {
                line-height: 1rem;
                padding: .5rem 0;
            }
        }
    }
}