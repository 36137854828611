#header-desktop {
    position: sticky;
    top: 20px;
    z-index: $zindex-sticky;
    height: 58px;
    .header-desktop--up {
        .holder {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-input {
                margin: 0 auto 0 auto;
                width: 100%;
            }
            .header-forms {
                text-wrap: nowrap;
                position: relative;
                margin: 0 0 0 auto;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                font-family: $font-family-secundary;
                font-weight: 400;
                font-size: .875rem;
                letter-spacing: 0.12em;
                &--auth {
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 20px;
                    width: 180px;
                    padding-top: .5rem;
                    z-index: $zindex-modal-backdrop;
                }
                &--group {
                    display: flex;
                    align-items: center;
                    img {
                        padding-right: .5rem;
                    }
                }
                &--register {
                    padding-left: 1rem;
                    color: $primary-color-2;
                }
                &--signup {
                    color: $primary-color-1;
                }
            }
            .autenticado:hover .header-forms--auth {
                display: block;
            }
        }
    }
}
