// $grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1900px);
// $container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1141px);
$utilities: ( "opacity": ( property: opacity, values: ( 0: 0, 15: .15, 25: .25, 35: .35, 50: .5, 75: .75, 100: 1, )));

// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// my scss
@import 'custom/main';
img,
video {
    aspect-ratio: attr(width) / attr(height);
}

img[src=""],
img:not([src]) {
    visibility: hidden;
    width: attr(width);
    height: attr(height);
}
