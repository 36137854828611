#como-cadastrar{
    .row {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .description {
        font-size: .775rem;
    }
    figure {
        margin-bottom: 0;
    }
    font-family: 'Montserrat';
    .alinhar-vertical {
        display: flex;
        align-items: center;
    }
    p {
        color: #6e6e6e;
        margin-bottom: 0;
        font-size: .875;
        font-weight: 500;
        line-height: 1rem;
    }
    .barra-old {
        width: 100%;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 2px solid #c2c2c2;
    }
    .font-border {
        font-weight: 700;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #F0B501;
        font-size: 5rem;
        line-height: 5rem;
        color: white;
    }
    .font-number {
        font-size: 0.775rem;
        line-height: 1rem;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    .font-color {
        font-size: .875rem;
        color: #F0B501;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }
    .header {
        padding-top: 1.5rem;
        .left-column {}
        .right-column {
            p {
                text-transform: uppercase;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.2rem;
            }
        }
        .ordem-right {
            background-color: white;
            width: 120px;
            text-align: center;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 75%;
            left: 75%;
            line-height: .875rem;
        }
    }
    @include media-breakpoint-up(md) {}
}
