    body {
    background-color: $body-bg;
    font-family: $font-family-secundary;
}

@include media-breakpoint-down(md) {
    .container {
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
}

p,
figure,
h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 0;
}

p {
    font-family: $font-family-secundary;
}

a {
    text-decoration: none !important;
}

.bkg-white {
    background-color: #fff;
}

img.lazy-image {
    opacity: 0.1;
    will-change: opacity;
    transition: all 0.3s;
}

img.lazy-image.loaded {
    opacity: 1;
}

.swal2-container {
    z-index: 100000!important;
}
.swal2-my-z-index{
    z-index: 999999 !important;
}

.btn-pill {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 100%;
    border: $primary-color-1;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: .875rem;
    line-height: .875rem;
    text-transform: uppercase;
    color: white;
    background: $primary-color-1;
    img {
        padding-right: .3rem;
    }
    &:focus {
        outline: 0px dotted;
        box-shadow: 0 0 0 0rem transparent;
    }
    &:hover {
        background: $primary-color-2;
        color: white;
    }
}

.my-hover {
    &:hover {
        opacity: .75;
    }
}
.my-hover-shadow{
    &:hover{
        @extend .shadow;
    }
}

.hover-shadow {
    &:hover {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        transition: .5s ease all;
    }
}

.btn-fast-black{
    color: white;
    border-color: $alert-color-success-1;
    @extend .rounded-pill;
    background-color: $alert-color-success-1;
}

.btn-fast-buy {
    text-transform: uppercase;
    font-size: .875rem;
    color: $alert-color-success-1;
    margin: 0.6rem 0;
    border-radius: 12px;
    border: none;
    img {
        margin-right: .7rem;
    }
    &:focus {
        outline: 0px dotted;
        box-shadow: 0 0 0 0rem transparent;
    }
}

.btn-see-mobile {
    text-align: center;
    text-transform: uppercase;
    color: white;
    width: 100%;
    background: $primary-color-1;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    padding: .5rem 0;
    &:hover {
        opacity: .9;
    }
}

.border-radius {
    border-radius: $border-radius;
}

.overlay {
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.droppable-down::after {
    display: inline-block;
    margin-left: .6em;
    vertical-align: 0.133em;
    height: 100%;
    content: "";
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}

.droppable-next {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.12em;
    &::before {
        display: inline-block;
        margin-right: 1rem;
        vertical-align: center;
        height: 100%;
        content: "";
        border-top: 0.4em solid transparent;
        border-right: 0;
        border-bottom: 0.4em solid transparent;
        border-left: 0.4em solid;
    }
}

.fill {
    width: 100%;
    display: block;
    height: 1px;
    border-top: 1px solid #C4C4C4;
}

.whatsapp-widget {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 30px;
    right: 15px;
    z-index: 1000;
    .blzvQB {
        width: 100px;
        height: 60px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        float: right;
        background-color: #696F73;
        box-shadow: none;
        color: rgb(255, 255, 255);
        cursor: pointer;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        border-radius: 30px 8px 30px 30px;
        padding: 8px;
        transition: all 0.1s ease-out 0s;
        span {
            font-weight: bold;
            font-size: 1rem;
        }
    }
}

.ativo-results {
    .description {
        width: 100%;
    }
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin-right: -9px;
        margin-left: -9px;
        li {
            text-align: center;
            color: $primary-color-1;
            border-radius: $border-radius;
            flex: 1 0 21%;
            margin: 10px;
            padding: 15px;
            background-color: #f1f1f1;
            font-size: .9rem;
            line-height: 1.2rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @extend .shadow-sm;
        }
    }
    @include media-breakpoint-down(sm) {
        ul{
            li{
                flex: 1 0 46%;
            }
        }
    }
}

// mobile  bootstrap 5------------------------------------------------------------------------------------
.btn{
    border-radius: calc($border-radius /2);
}
.btn-color-1{
    background-color: $btn-color-1;
    color: #fff;
    @extend .btn;
    @extend .shadow-sm;
    &:hover, &:active{
        background-color: lighten($btn-color-1, 10%);
        color: #fff;
        @extend .shadow;
    }
}

.fs-12 {
    font-size: .75rem;
}
.fs-mobile-12 {
    @include media-breakpoint-down(md) {
        font-size: .75rem;
    }
}
.fs-16{
    font-size: 1rem;
    line-height: 1.4rem;
}
.w-md-100{
    @include media-breakpoint-down(md) {
        width: 100% !important;
    }
}
.w-md-75{
    @include media-breakpoint-down(md) {
        width: 75% !important;
    }
}
.w-lg-100{
    @include media-breakpoint-down(lg) {
        width: 100% !important;
    }
}
.w-lg-75{
    @include media-breakpoint-down(lg) {
        width: 75% !important;
    }
}

.primary-color-1 {
    color: $primary-color-1 !important;
}

.primary-color-2 {
    color: $primary-color-2 !important;
}

.primary-color-3 {
    color: $primary-color-3 !important;
}

.primary-color-4 {
    color: $primary-color-4 !important;
}

.secondary-color-1 {
    color: $secondary-color-1 !important;
}

.secondary-color-2 {
    color: $secondary-color-2 !important;
}

.secondary-color-3 {
    color: $secondary-color-3 !important;
}

.secondary-color-4 {
    color: $secondary-color-3 !important;
}

.alert-color-success-1 {
    color: $alert-color-success-1 !important;
}
.alert-color-success-3 {
    color: $alert-color-success-3 !important;
}

.bg-primary-color-1 {
    background-color: $primary-color-1 !important;
}

.bg-primary-color-2 {
    background-color: $primary-color-2 !important;
    border: $primary-color-2 !important;
}

.bg-primary-color-3 {
    background-color: $primary-color-3 !important;
    border: $primary-color-3 !important;
}

.bg-primary-color-4 {
    background-color: $primary-color-4 !important;
    border: $primary-color-4 !important;
}

.bg-primary-color-5 {
    background-color: $primary-color-5 !important;
    border: $primary-color-5 !important;
}

.bg-secondary-color-1 {
    background-color: $secondary-color-1 !important;
}

.bg-secondary-color-2 {
    background-color: $secondary-color-2 !important;
}

.bg-secondary-color-3 {
    background-color: $secondary-color-3 !important;
}

.bg-secondary-color-4 {
    background: $secondary-color-4;
}

.bg-secondary-color-5 {
    background: $secondary-color-5;
}

.bg-alert-color-1 {
    background-color: $alert-color-success-1 !important;
    border: $alert-color-success-1 !important;
}
.bg-alert-color-2 {
    background-color: $alert-color-success-2 !important;
    border: $alert-color-success-2 !important;
}

.bg-menu {
    background-color: $bg-menu;
}
.bg-page-product{
    background-color: #f6f6f6;
}

.my-row {
    margin-right: 0px;
    margin-left: 0px;
}

.letter-spacing-00 {
    letter-spacing: -0.03em;
}

.letter-spacing-02 {
    letter-spacing: 0.02em;
}

.letter-spacing-05 {
    letter-spacing: 0.05em;
}

.letter-spacing-12 {
    letter-spacing: 0.12em;
}

.font-primary {
    font-family: $font-family-primary !important;
}

.font-secondary {
    font-family: $font-family-secundary !important;
}
.font-merriweather {
    font-family: 'Merriweather', serif !important;
}

.my-shadow {
    box-shadow:  1px 1px 4px rgba(0, 0, 0, 0.1);
}

.fw-600 {
    font-weight: 600;
}

.product-description-image {
    span,
    p,
    div,
    li,
    h4,
    h5 {
        font-family: $font-family-secundary !important;
        color: $secondary-color-1;
        font-size: 18px !important;
        line-height: 28px;
    }
}

.blog-content-desk {
    word-wrap: break-word;
    img {
        max-width: 100%;
        height: auto;
    }
    p,
    div,
    span,
    ul,
    li {
        color: $secondary-color-1 !important;
        font-size: 1.1rem !important;
        line-height: 1.7rem !important;
        font-family: $font-family-secundary !important;
    }
    a, a > span{
       color: #0d6efd !important;
    }
    h2, h2 > p span div {
        font-weight: bold !important;
        margin-bottom: 1rem !important;
    }
}
.barra {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    padding: 0;
    height: 8px;
    width: 100%;
    &-right {
        width: 52%;
        left: 75%;
    }
    &-left {
        width: 52%;
        left: 25%;
    }
}

input{
    font-size: 16px !important;
}

.my-container-fluid{
    @extend .container-fluid;
    max-width: 1360px;
}
@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 1;}
}

.fade-in {
   animation: fade-in 1s ease;
}
.my-text-warning{
    color: rgb(255, 217, 0);
}
// color: #66000000 ;
.position-md-absolute{
    @include media-breakpoint-up(sm) {
        position: block !important;
    }
    @include media-breakpoint-up(md) {
        position: absolute !important;
    }
}
// config black
.caixa {
    position: relative;
    width: 100%; /* Ajustar conforme necessário */
    height: 100%; /* Ajustar conforme necessário */
    color: #fff; /* Cor do texto */
    background-color: black; /* Cor de fundo */
    border-radius: 20px; /* Garantir que a borda não ultrapasse a caixa */
    --delay: calc(var(--i) * 0.5s);
}

.caixa::before, .caixa::after {
    content: '';
    top:-2px;
    left: -2px;
    position: absolute;
    z-index: -1;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    // background: linear-gradient(100deg, #289BDD -0.33%, #DAEC72 16.68%, #E2CA6F 20.61%, #ECA56D 25.85%, #F4886B 31.08%, #F9736A 35.01%, #FC6769 40.24%, #FE6369 44.17%, #FE6766 49.4%, #FE735E 53.33%, #FE8750 58.56%, #FEA43D 62.49%, #FEC825 67.72%, #FFDE17 70.34%, #52DDA9 97.83%, #69BAFA 110.91%);
    background: linear-gradient(91deg, #28B9C2 23.86%, #DAEC72 35.55%, #FDBCF6 46.14%, #B3B1F8 57.27%, #52DDA9 68.97%, #64BAFA 79.55%);
    background-size: 300%;
    border-radius: 22px; /* Ajuste conforme necessário */
    animation: AnimarBorda 8s linear alternate infinite;
    animation-delay: var(--delay); /* Aplica o delay dinâmico */
}

.caixa::after {
    filter: blur(2px);
}

@keyframes AnimarBorda {
    0% {
        background-position: 0;
    }
    100% {
        background-position: 300%;
    }
}

.caixa-day {
    position: relative;
    width: 100%; /* Ajustar conforme necessário */
    height: 100%; /* Ajustar conforme necessário */
    color: #fff; /* Cor do texto */
    background-color: black; /* Cor de fundo */
    border-radius: 20px; /* Garantir que a borda não ultrapasse a caixa */
    --delay: calc(var(--i) * 0.5s);
}

.caixa-day::before, .caixa-day::after {
    content: '';
    top:-2px;
    left: -2px;
    position: absolute;
    z-index: -1;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: linear-gradient(100deg, #289BDD -0.33%, #DAEC72 16.68%, #E2CA6F 20.61%, #ECA56D 25.85%, #F4886B 31.08%, #F9736A 35.01%, #FC6769 40.24%, #FE6369 44.17%, #FE6766 49.4%, #FE735E 53.33%, #FE8750 58.56%, #FEA43D 62.49%, #FEC825 67.72%, #FFDE17 70.34%, #52DDA9 97.83%, #69BAFA 110.91%);
    // background: linear-gradient(91deg, #28B9C2 23.86%, #DAEC72 35.55%, #FDBCF6 46.14%, #B3B1F8 57.27%, #52DDA9 68.97%, #64BAFA 79.55%);
    background-size: 300%;
    border-radius: 22px; /* Ajuste conforme necessário */
    animation: AnimarBorda2 8s linear alternate infinite;
    animation-delay: var(--delay); /* Aplica o delay dinâmico */
}
.font-count{
    font-size: 32px;
    @include media-breakpoint-up(sm) {
        font-size: 50px;
    }
}
.caixa-day::after {
    filter: blur(2px);
}

@keyframes AnimarBorda2 {
    0% {
        background-position: 0;
    }
    100% {
        background-position: 300%;
    }
}
.bg-menu-black {
    background-color: #121212;
}
.border-blue-black{
    border-color:#00D0E4 !important;
}
.border-yellow-black{
    border-color: #FFB000 !important;
}
.text-blue-black{
    color: #00D0E4 !important;
}
.bg-week{
    background-color:#1E1E1E !important;
}
.bg-card-week{
    background-color:#2E2E2E !important;
}
.text-yellow-black{
    color: #FFB000 !important;
}
.gradient-fru-fru {
    width: 100%;
    height: 200px; /* Ajuste a altura conforme necessário */
    background: linear-gradient(45deg, #289BDD, #DAEC72 19%, #FE6369 40%, #FFDE17 60%, #52DDA9 81%, #69BAFA 99%);
}
.custom-scroll {
    overflow-x: scroll; /* Permite a rolagem horizontal */ scrollbar-width: none; /* Para navegadores Firefox */ -ms-overflow-style: none; /* Para navegadores Internet Explorer e Edge */
}
.custom-scroll::-webkit-scrollbar {
    display: none;
}
.bg-black-header{
    background-color: #232323 !important;
}
.bg-black{
    background-color:#1E1E1E!important;
}
.bg-secondary-black{
    background-color: #4C4C4C !important;
}
.bg-card-black{
    background:#2E2E2E !important;
}
.text-pink-black{
    color: #ED006C !important;
}
.bg-pink-black{
    background-color: #ED006C !important;
}
.text-week{
    color: #ea558e ;
}
.text-price-black{
    background: radial-gradient(84.69% 108.81% at 0% 7.52%, #28B9C2 0%, #DAEC72 21%, #FDBCF6 40%, #B3B1F8 60%, #52DDA9 81%, #64BAFA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-price-black-day{
    background: linear-gradient(100deg, #289BDD -0.33%, #DAEC72 16.68%, #E2CA6F 20.61%, #ECA56D 25.85%, #F4886B 31.08%, #F9736A 35.01%, #FC6769 40.24%, #FE6369 44.17%, #FE6766 49.4%, #FE735E 53.33%, #FE8750 58.56%, #FEA43D 62.49%, #FEC825 67.72%, #FFDE17 70.34%, #52DDA9 97.83%, #69BAFA 110.91%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-color-black{
    color: #FFD8C7 ;
}
.bg-btn-week{
    background-color: #2B2775 !important;
}
.shadow-white { box-shadow: 0 4px 8px rgba(89, 89, 89, 0.1), 0 6px 20px rgba(66, 66, 66, 0.1); }

.style-week{
    background-color:  #000228 !important;
    .page-product-desk{
        padding-bottom: 3rem;
        section{
            background-color: white;
        }
        .section-product{
            background-color: #233551 !important;
            h1,p,span,small,li{
                color: white !important;
            }
            .avise-me{
                label{
                    color:white !important;
                }
            }
            .descricao{
                color:white !important
            }
            .bg-alert-color-1{
                background-color: $alert-color-success-3 !important;
                color: #000000 !important;
                font-weight: bold !important;
                span{
                    color: #000000 !important;
                }
            }

        }

    }
}
.style-black{
    @extend .bg-black;
    .page-product-desk{
        padding-bottom: 3rem;
        section{
            background-color: white;
        }
        .section-product{
            @extend .bg-secondary-black;
            h1,p,span,small,li{
                color: white !important;
            }
            .avise-me{
                label{
                    color:white !important;
                }
            }
            .descricao{
                color:white !important
            }
            .bg-alert-color-1{
                background-color: $alert-color-success-3 !important;
                color: #000000 !important;
                font-weight: bold !important;
                span{
                    color: #000000 !important;
                }
            }

        }

    }
}
.style-week-mobile{
    .page-product-mobile{
        background-color: #000228 !important;
        .section-product{
            background-color: #233551 !important;
            p,span,li,h1,small{
                color: white!important;
            }
            .fixed-bottom{
                background-color: #03132B !important;
                .bg-alert-color-1{
                    background-color: $alert-color-success-3 !important;
                    color: #000000 !important;
                    font-weight: bold !important;
                    span{
                        color: #000000 !important;
                    }
                }
            }
        }
    }
}

.style-black-mobile{
    .page-product-mobile{
        @extend .bg-black;
        .section-product{
            @extend .bg-secondary-black;
            p,span,li,h1,small{
                color: white!important;
            }
            .fixed-bottom{
                background-color:#000000 !important;
                .bg-alert-color-1{
                    background-color: $alert-color-success-3 !important;
                    color: #000000 !important;
                    font-weight: bold !important;
                    span{
                        color: #000000 !important;
                    }
                }
            }
        }
    }
}
.mt-8{
    margin-top: 6rem !important;
}

