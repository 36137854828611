#navbar-mobile {
    position: sticky;
    top: 20px;
    z-index: $zindex-sticky;
    .navbar-hamburguer {
        width: 25px;
        height: 22px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background-color: #97A7AF;
            border-radius: 3px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
            &:nth-child(1) {
                top: 0px;
            }
            &:nth-child(2) {
                top: 9px;
            }
            &:nth-child(3) {
                top: 18px;
            }
        }
        &.active,
        &--white {
            span {
                &:nth-child(1) {
                    top: 9px;
                    -webkit-transform: rotate(135deg);
                    -moz-transform: rotate(135deg);
                    -o-transform: rotate(135deg);
                    transform: rotate(135deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                    left: -60px;
                }
                &:nth-child(3) {
                    top: 9px;
                    -webkit-transform: rotate(-135deg);
                    -moz-transform: rotate(-135deg);
                    -o-transform: rotate(-135deg);
                    transform: rotate(-135deg);
                }
            }
        }
    }
    .navbar-mobile {
        z-index: $zindex-popover;
        position: absolute;
        top: -20px !important;
        left: -100%;
        width: 100%;
        height: 100vh;
        transition: 0.500s ease;
    }
}
