#footer {
    background-image: url('/assets/images/bg-footer.jpg');
    background-position: top center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    .footer {
        &--up {
            padding: 4rem 0;
            .footer-titulos {
                height: 40px;
                font-family: $font-family-secundary;
                font-size: 1.125rem;
                line-height: 1.25rem;
                letter-spacing: 0.05em;
                color: $secondary-color-1;
                margin-bottom: 1rem;
            }
            .redes {
                .footer-titulos {
                    text-align: center;
                    @include media-breakpoint-down(md) {
                        text-align: left;
                    }
                }
                &--icons {
                    display: flex;
                    .figure-1 {
                        margin-left: auto;
                        margin-right: 1rem;
                        @include media-breakpoint-down(md) {
                            margin-left: 0;
                            margin-bottom: 2rem;
                        }
                    }
                    .figure-2 {
                        margin-right: auto;
                    }
                }
            }
            .links {
                ul {
                    padding-inline-start: 0px;
                    li {
                        list-style-type: none;
                        a {
                            font-family: $font-family-secundary;
                            font-weight: 600;
                            font-size: .875rem;
                            line-height: 1.5rem;
                            display: flex;
                            letter-spacing: 0.12em;
                            text-transform: uppercase;
                            color: $primary-color-1;
                        }
                    }
                }
            }
            .pagamento {
                &--imgs {
                    display: flex;
                    justify-content: space-between;
                }
            }
            .contato {
                &--dados {
                    font-family: $font-family-secundary;
                    font-style: normal;
                    font-weight: 600;
                    font-size: .75rem;
                    line-height: .875rem;
                    letter-spacing: 0.05em;
                    color: $primary-color-2;
                }
            }
        }
        &--down {
            padding-bottom: 1rem;
            .holder {
                display: flex;
                align-items: center;
                img {
                    margin-right: 1rem;
                }
                p {
                    font-family: $font-family-secundary;
                    font-size: .875rem;
                    line-height: 1.1rem;
                    color: #000000;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            .footer {
                &--up {
                    padding: 2rem 0 1rem 0;
                    .footer-titulos {
                        margin-top: 3rem;
                        margin-bottom: 1.5rem;
                        height: inherit;
                    }
                    .redes {
                        &--icons {
                            .figure-1 {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .links {
                        ul {
                            margin-bottom: 0;
                            li {
                                a {
                                    font-size: .875rem;
                                    line-height: 2rem;
                                }
                            }
                        }
                    }
                    .pagamento {
                        .holder-pagamento {
                            display: flex;
                            justify-content: space-between;
                            .pagamento--imgs {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}
