.aa-hits {
    color: #333;
    font-size: 0.9rem;
    // overflow: scroll;
    // height: 100vh;
    // display: none;
    z-index: 100;
    a {
        color: #555;
        line-height: 1.2rem;
    }
    #suggest {
        span {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.input-search {
    .searchbox {
        width: 100% !important;
        height: 40px;
        font-size: 13px;
    }
    .searchbox__wrapper {
        width: 100%;
        height: 100%;
    }
    .searchbox__input {
        width: 100%;
        display: inline-block;
        -webkit-transition: box-shadow .4s ease, background .4s ease;
        transition: box-shadow .4s ease, background .4s ease;
        background: #FFFFFF;
        padding: 0;
        padding-right: 30px;
        padding-left: 37px;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        white-space: normal;
        font-size: inherit;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .searchbox__input::-webkit-search-decoration,
    .searchbox__input::-webkit-search-cancel-button,
    .searchbox__input::-webkit-search-results-button,
    .searchbox__input::-webkit-search-results-decoration {
        display: none;
    }
    .searchbox__input:focus-visible {
        outline: none;
    }
    .searchbox__input:focus,
    .searchbox__input:active {
        border: none;
    }
    .searchbox__input::-webkit-input-placeholder {
        color: #AAAAAA;
    }
    .searchbox__input::-moz-placeholder {
        color: #AAAAAA;
    }
    .searchbox__input:-ms-input-placeholder {
        color: #AAAAAA;
    }
    .searchbox__input::placeholder {
        color: #888888;
    }
    .searchbox__submit {
        position: absolute;
        top: 0;
        right: inherit;
        left: 0;
        margin: 0;
        border: 0;
        border-radius: 18px 0 0 18px;
        background-color: rgba(255, 255, 255, 0);
        padding: 0;
        width: 37px;
        height: 100%;
        vertical-align: middle;
        text-align: center;
        font-size: inherit;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .searchbox__submit::before {
        display: inline-block;
        margin-right: -4px;
        height: 100%;
        vertical-align: middle;
        content: '';
    }
    .searchbox__submit:hover,
    .searchbox__submit:active {
        cursor: pointer;
    }
    .searchbox__submit:focus {
        outline: none ;
    }
    .searchbox__submit svg {
        width: 22px;
        height: 22px;
        vertical-align: middle;
        fill: #666666;
    }
}

.search-up {
    z-index: 1019;
    position: absolute;
    opacity: 0;
    top: -100%;
    left: 0px;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    transition: .6s ease;
}

.search-mobile {
    z-index: 99999999;
    position: absolute;
    opacity: 0;
    top: -20px;
    left: -100%;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    transition: 0.500s ease;
}
